@import './scss/style.scss';

@import 'bootstrap/dist/css/bootstrap.min.css';
@import './styles/variables.scss';


.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
}


.react-bootstrap-table {
  th {
    cursor: pointer;

    .filter-label {
      display: block;
      margin-top: 4px;
    }
  }
}


.btn-primary{
  background-color: $base-success;
  border-color: $base-success;
  &:hover{
    background-color: $base-success;
    border-color: $base-success;
  }
}

.table-page{
  .card-header{
    background-color: white;
  }
  table{
    th{
      border-top: 0px !important;
      border-bottom: 1px solid #dee2e6;
      vertical-align: top;
      .icon{
        font-size: 12px;
      }
      // border-bottom: 0px !important;
    }
    td{
      border-top: 0px !important;
    }
  }

  .page-item{
    &.active{
      .page-link{
        background-color: $base-success;
        border-color: $base-success;
      }
    }
  }
}

a{
  color: $base-success;
  &:hover{
    color: $base-success;
  }
}

.nav-item {
  &.active {
    border-bottom: 2px solid $base-success;
    padding: 5px 0px !important;    
    a {      
      color: $base-success !important;      
    }
  }
}
