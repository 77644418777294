@import "../../sass/variables";
@import "../../styles/variables.scss";

.standard-input {  
  .icon{
    font-size: 16px;
  }
  input {
    border-radius: 4px;
    
    border: solid 1px $base-success;
    height: 3rem;
    font-size: 1rem;
    padding: 1rem;

    &:focus {
      
      // border: solid 1px $giraffe-primary;
      border: solid 1px $base-success;
      box-shadow: none;
    }

    &.error {
      border: solid 1px $giraffe-danger;
      color: $giraffe-danger;
    }

    //Hide up and down incrementors for input box
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }
  }
  ::placeholder {
    color: #808080;
  }

  label {
    font-size: 1rem;
  }

  .help-text {
    font-size: 0.75rem;
    opacity: 0.5;
  }
  .error-text {
    font-size: 0.75rem;
    color: $giraffe-danger;
  }
}
.error{
  input{
    border: solid 1px $giraffe-danger;
    color: $giraffe-danger;
  }
}

.otp{
  display: inline-block;
  input{
      font-size: 20px;
  }
  
}