.login-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .standard-input {
    max-width: 350px;
  }
  .login-button {
    max-width: 350px;
  }
}
