@import "/src/styles/variables.scss";

.auth-side-info-container {
  background-color: $base-success;
  color: $base-banner-text;
  height: 100%;
  img {
    max-height: 80%;
  }
}
